import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  {
    path: 'tab',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login-main',
    loadChildren: () => import('./login-main/login-main.module').then(m => m.LoginMainPageModule)
  },
  {
    path: 'select-type-service',
    loadChildren: () => import('./select-type-service/select-type-service.module').then(m => m.SelectTypeServicePageModule)
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  // },
  {
    path: '',
    redirectTo: 'register',
    pathMatch: 'full'
  },
  {
    path: 'login-ones-id',
    loadChildren: () => import('./login-ones-id/login-ones-id.module').then(m => m.LoginOnesIdPageModule)
  },
  {
    path: 'login-ones-id-next',
    loadChildren: () => import('./login-ones-id-next/login-ones-id-next.module').then(m => m.LoginOnesIdNextPageModule)
  },
  {
    path: 'service-place-address',
    loadChildren: () => import('./service-place-address/service-place-address.module').then(m => m.ServicePlaceAddressPageModule)
  },
  {
    path: 'human-resource-first-page',
    loadChildren: () => import('./human-resource-first-page/human-resource-first-page.module').then(m => m.HumanResourceFirstPagePageModule)
  },
  {
    path: 'login-main',
    loadChildren: () => import('./login-main/login-main.module').then(m => m.LoginMainPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'main-menu',
    loadChildren: () => import('./main-menu/main-menu.module').then(m => m.MainMenuPageModule)
  },
  {
    path: 'dashboard-employee',
    loadChildren: () => import('./dashboard-employee/dashboard-employee.module').then(m => m.DashboardEmployeePageModule)
  },
  {
    path: 'private-type-main',
    loadChildren: () => import('./private-type-main/private-type-main.module').then(m => m.PrivateTypeMainPageModule)
  },
  {
    path: 'factory-place-address',
    loadChildren: () => import('./factory-place-address/factory-place-address.module').then(m => m.FactoryPlaceAddressPageModule)
  },
  {
    path: 'search-agency-modal',
    loadChildren: () => import('./modal/search-agency-modal/search-agency-modal.module').then(m => m.SearchAgencyModalPageModule)
  },
  {
    path: 'show-app',
    loadChildren: () => import('./modal/show-app/show-app.module').then(m => m.ShowAppPageModule)
  },
  {
    path: 'health-checks',
    loadChildren: () => import('./health-checks/health-checks.module').then(m => m.HealthChecksPageModule)
  },
  {
    path: 'health-checks-hospital',
    loadChildren: () => import('./health-checks-hospital/health-checks-hospital.module').then(m => m.HealthChecksHospitalPageModule)
  },
  {
    path: 'list-health-checks',
    loadChildren: () => import('./list-health-checks/list-health-checks.module').then(m => m.ListHealthChecksPageModule)
  },
  {
    path: 'list-health-checks-hospital',
    loadChildren: () => import('./list-health-checks-hospital/list-health-hospital-checks.module').then(m => m.ListHealthChecksPageModuleHospital)
  },
  {
    path: 'upload-health-check-private',
    loadChildren: () => import('./upload-health-check-private/upload-health-check-private.module').then(m => m.UploadHealthCheckPrivatePageModule)
  },
  {
    path: 'search-hospital-modal',
    loadChildren: () => import('./modal/search-hospital-modal/search-hospital-modal.module').then(m => m.SearchHospitalModalPageModule)
  },
  {
    path: 'list-employees',
    loadChildren: () => import('./list-employees/list-employees.module').then(m => m.ListEmployeesPageModule)
  },
  {
    path: 'atk-detail-modal',
    loadChildren: () => import('./atk-detail-modal/atk-detail-modal.module').then(m => m.AtkDetailModalPageModule)
  },
  {
    path: 'service-unit-information',
    loadChildren: () => import('./service-unit-information/service-unit-information.module').then(m => m.ServiceUnitInformationPageModule)
  },
  {
    path: 'abnormal-form',
    loadChildren: () => import('./abnormal-form/abnormal-form.module').then(m => m.AbnormalFormPageModule)
  },
  {
    path: 'government-hospital-service-select',
    loadChildren: () => import('./government-hospital-service-select/government-hospital-service-select.module').then(m => m.GovernmentHospitalServiceSelectPageModule)
  },
  {
    path: 'human-resource-first-hospital',
    loadChildren: () => import('./human-resource-first-hospital/human-resource-first-hospital.module').then(m => m.HumanResourceFirstHospitalPageModule)
  },
  {
    path: 'main-menu-hospital',
    loadChildren: () => import('./main-menu-hospital/main-menu-hospital.module').then(m => m.MainMenuHospitalPageModule)
  },
  {
    path: 'upload-health-check-hospital',
    loadChildren: () => import('./upload-health-check-hospital/upload-health-check-hospital.module').then(m => m.UploadHealthCheckHospitalPageModule)
  },
  {
    path: 'manage-user',
    loadChildren: () => import('./manage-user/manage-user.module').then(m => m.ManageUserPageModule)
  },
  {
    path: 'edit-information-user-modal',
    loadChildren: () => import('./modal/edit-information-user-modal/edit-information-user-modal.module').then(m => m.EditInformationUserModalPageModule)
  },
  {
    path: 'list-employees-hospital',
    loadChildren: () => import('./list-employees-hospital/list-employees-hospital.module').then(m => m.ListEmployeesHospitalPageModule)
  },
  {
    path: 'manage-user-hospital',
    loadChildren: () => import('./manage-user-hospital/manage-user-hospital.module').then(m => m.ManageUserHospitalPageModule)
  },
  {
    path: 'health-history-modal',
    loadChildren: () => import('./modal/health-history-modal/health-history-modal.module').then(m => m.HealthHistoryModalPageModule)
  },
  {
    path: 'add-factory',
    loadChildren: () => import('./add-factory/add-factory.module').then( m => m.AddFactoryPageModule)
  },
  {
    path: 'add-factory-hospital-select',
    loadChildren: () => import('./add-factory-hospital-select/add-factory-hospital-select.module').then( m => m.AddFactoryHospitalSelectPageModule)
  },
  {
    path: 'health-condition',
    loadChildren: () => import('./health-condition/health-condition.module').then( m => m.HealthConditionPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'register-onesid',
    loadChildren: () => import('./register-onesid/register-onesid.module').then( m => m.RegisterOnesidPageModule)
  },
  {
    path: 'add-agency',
    loadChildren: () => import('./add-agency/add-agency.module').then( m => m.AddAgencyPageModule)
  },
  {
    path: 'require-reset-password',
    loadChildren: () => import('./require-reset-password/require-reset-password.module').then( m => m.RequireResetPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'debug-videocall',
    loadChildren: () => import('./videocall/debug-videocall/debug-videocall.module').then( m => m.DebugVideocallPageModule)
  },
  {
    path: 'videocall',
    loadChildren: () => import('./videocall/videocall/videocall.module').then( m => m.VideocallPageModule)
  },
  {
    path: 'debug-videocall-list',
    loadChildren: () => import('./videocall/debug-videocall-list/debug-videocall-list.module').then( m => m.DebugVideocallListPageModule)
  },
  {
    path: 'ncds-table',
    loadChildren: () => import('./ncds-table/ncds-table.module').then( m => m.NcdsTablePageModule)
  },
  {
    path: 'download-executive-summary',
    loadChildren: () => import('./download-executive-summary/download-executive-summary.module').then( m => m.DownloadExecutiveSummaryPageModule)
  },
  {
    path: 'delete-health-check-year',
    loadChildren: () => import('./modal/delete-health-check-year/delete-health-check-year.module').then( m => m.DeleteHealthCheckYearPageModule)
  },
  {
    path: 'room-telemed',
    loadChildren: () => import('./room-telemed/room-telemed.module').then( m => m.RoomTelemedPageModule)
  },
  {
    path: 'export-executive-summary-excel',
    loadChildren: () => import('./export-executive-summary-excel/export-executive-summary-excel.module').then( m => m.ExportExecutiveSummaryExcelPageModule)
  },
  {
    path: 'abnormal-health-employees',
    loadChildren: () => import('./abnormal-health-employees/abnormal-health-employees.module').then( m => m.AbnormalHealthEmployeesPageModule)
  },
  {
    path: 'abnormal-health-employees-hospital',
    loadChildren: () => import('./abnormal-health-employees-hospital/abnormal-health-employees-hospital.module').then( m => m.AbnormalHealthEmployeesHospitalPageModule)
  },
  {
    path: 'modal-detail-department',
    loadChildren: () => import('./modal-detail-department/modal-detail-department.module').then( m => m.ModalDetailDepartmentPageModule)
  },
  {
    path: 'edit-abnormal-health-employees-hotpital',
    loadChildren: () => import('./edit-abnormal-health-employees-hotpital/edit-abnormal-health-employees-hotpital.module').then( m => m.EditAbnormalHealthEmployeesHotpitalPageModule)
  },
  {
    path: 'modal-edit-factors',
    loadChildren: () => import('./modal-edit-factors/modal-edit-factors.module').then( m => m.ModalEditFactorsPageModule)
  },
  {
    path: 'modal-export-report',
    loadChildren: () => import('./modal-export-report/modal-export-report.module').then( m => m.ModalExportReportPageModule)
  },
  {
    path: 'edit-abnormal-health-employees',
    loadChildren: () => import('./edit-abnormal-health-employees/edit-abnormal-health-employees.module').then( m => m.EditAbnormalHealthEmployeesPageModule)
  },
  {
    path: 'modal-edit-detail-department',
    loadChildren: () => import('./modal-edit-detail-department/modal-edit-detail-department.module').then( m => m.ModalEditDetailDepartmentPageModule)
  },
  {
    path: 'export-pdf-download-document',
    loadChildren: () => import('./export-pdf-download-document/export-pdf-download-document.module').then( m => m.ExportPdfDownloadDocumentPageModule)
  },
  {
    path: 'export-pdf-download-document-hospital',
    loadChildren: () => import('./export-pdf-download-document-hospital/export-pdf-download-document-hospital.module').then( m => m.ExportPdfDownloadDocumentHospitalPageModule)
  },
  {
    path: 'add-abnormal-health-employees',
    loadChildren: () => import('./add-abnormal-health-employees/add-abnormal-health-employees.module').then( m => m.AddAbnormalHealthEmployeesPageModule)
  },
  {
    path: 'show-abnormal-health-employees',
    loadChildren: () => import('./show-abnormal-health-employees/show-abnormal-health-employees.module').then( m => m.ShowAbnormalHealthEmployeesPageModule)
  },
  {
    path: 'add-abnormal-health-employees-hospital',
    loadChildren: () => import('./add-abnormal-health-employees-hospital/add-abnormal-health-employees-hospital.module').then( m => m.AddAbnormalHealthEmployeesHospitalPageModule)
  },
  {
    path: 'show-abnormal-health-employees-hospital',
    loadChildren: () => import('./show-abnormal-health-employees-hospital/show-abnormal-health-employees-hospital.module').then( m => m.ShowAbnormalHealthEmployeesHospitalPageModule)
  },
  {
    path: 'frequently-asked-questions',
    loadChildren: () => import('./frequently-asked-questions/frequently-asked-questions.module').then( m => m.FrequentlyAskedQuestionsPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FAQPageModule)
  },
  {
    path: 'faq-hospital',
    loadChildren: () => import('./faq-hospital/faq-hospital.module').then( m => m.FAQHospitalPageModule)
  },
  {
    path: 'privacy-notice',
    loadChildren: () => import('./privacy-notice/privacy-notice.module').then( m => m.PrivacyNoticePageModule)
  },





];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
